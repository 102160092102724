<template>
  <!-- 计价管理 -->
  <div ref="valuationManage" class="valuationManage">
    <div ref="form" class="form-area">
      <el-form
        :inline="true"
        :model="form"
        class="demo-form-inline"
        label-position="left"
        label-width="100px"
      >
        <el-form-item label="车商名称：">
          <el-select
            v-model="form.carServiceProviderId"
            clearable
            placeholder="请选择车商名称"
          >
            <el-option
              v-for="item in nameList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车型分类名称：">
          <el-select
            v-model="form.dictCode"
            clearable
            placeholder="请选择车型分类名称"
          >
            <el-option
              v-for="item in typeList"
              :key="item.dictCode"
              :label="item.dictValue"
              :value="item.dictCode"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="queryFun"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      :table-height="tableHeight"
      operation-width="140"
    >
      <template slot-scope="scope">
        <div>
          <el-button
            v-if="listFind('查看')"
            type="text"
            size="small"
            sort="primary"
            @click="seeFun(scope.scopeRow)"
            >查看</el-button
          >
          <el-button
            v-if="listFind('编辑')"
            type="text"
            size="small"
            sort="primary"
            @click="packageUpdateFun(scope.scopeRow)"
            >配置</el-button
          >
        </div>
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      >
      </el-pagination>
    </div>
    <charter-popup
      v-if="show"
      :header-text="headerText"
      max-height="795px"
      width="900"
      @closePopupFun="closePopupFun"
      @confirmFun="confirmBtn"
    >
      <div class="inner">
        <h3>车型信息</h3>
        <el-row :gutter="20">
          <el-col :span="12">
            <span>车商名称：</span>
            <span>{{ ruleForm.carServiceProviderName }}</span>
          </el-col>
          <el-col :span="12">
            <span>车型分类：</span>
            <span>{{ ruleForm.serviceVehicleModelName }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12" class="flex-box">
            <span>分类描述：</span>
            <div class="text">{{ ruleForm.serviceVehicleModelDescribe }}</div>
          </el-col>
          <el-col :span="12">
            <span>行李数：</span>
            <span>{{ ruleForm.luggageNum }}</span>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <span>座位数：</span>
            <span>{{ ruleForm.seatNum }}</span>
          </el-col>
          <el-col :span="12">
            <span>载客数：</span>
            <span>{{ ruleForm.passengerNum }}</span>
          </el-col>
        </el-row>

        <div class="customizedCharterCar-box">
          <div class="head">
            <h3>定制包车</h3>
            <el-switch
              v-model="ruleForm.customCharterStatus"
              active-color="#13ce66"
              inactive-color="#E3E3E3"
              :active-value="0"
              :inactive-value="1"
              :disabled="disabled"
              @change="changeCustomCharterStatus"
            ></el-switch>
          </div>
          <div class="costInfo-box">
            <div class="label-title">费用包含：</div>
            <div class="main">
              <!-- <div>基础费用包含：</div> -->
              <el-input
                v-model="ruleForm.costInfo"
                :autosize="{ minRows: 2, maxRows: 6 }"
                :disabled="disabled"
                show-word-limit
                resize="none"
                type="textarea"
                maxlength="500"
                placeholder="例如：车辆使用费、燃油费、司机服务费"
              ></el-input>
            </div>
          </div>
          <div class="costInfo-box">
            <div class="label-title">费用不含：</div>
            <div class="main">
              <div class="tips">
                <i class="el-icon-warning"></i
                >请添加行程中可能产生的额外费用收费项目及收费标准，用于展示给包车用户
              </div>
              <div class="table-box">
                <el-table
                v-if="ruleForm.charteredValuationCustom"
                :data="ruleForm.charteredValuationCustom.charteredCostItems"
                border
                style="width: 100%"
                height="200"
              >
                <el-table-column
                  prop="costName"
                  label="费用名称"
                  align="center"
                >
                </el-table-column>
                <el-table-column
                  prop="modelTypeDescription"
                  label="费用描述"
                  align="center"
                  width="300"
                >
                </el-table-column>
                <el-table-column prop="cost" label="收费标准" align="center">
                  <template slot-scope="scope">
                    <el-input
                      v-model.trim="scope.row.cost"
                      :disabled="disabled"
                      @input="
                        scope.row.cost = scope.row.cost.replace(
                          /^(0+)|[^\d]+/g,
                          ''
                        )
                      "
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="unit" label="单位" align="center">
                </el-table-column>
                <el-table-column
                  prop="status"
                  label="状态"
                  align="center"
                  width="100"
                >
                  <template slot-scope="scope">
                    <el-switch
                      v-model="scope.row.status"
                      active-color="#13ce66"
                      inactive-color="#E3E3E3"
                      :active-value="0"
                      :inactive-value="1"
                      :disabled="disabled"
                      @change="changeStatus(scope.row)"
                    ></el-switch>
                  </template>
                </el-table-column>
              </el-table>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </charter-popup>
  </div>
</template>
<script>
import {
  valuationManagePageAPI,
  carDealerRenderAPI,
  carTypeListAPI,
  charteredValuationManagementAPI,
  updateCharteredValuationManagementAPI,
} from "@/api/lib/api.js";
export default {
  data () {
    return {
      form: {
        dictCode: null,
        carServiceProviderId: null,
        currentPage: 1,
        pageSize: 10,
      },
      nameList: [],
      typeList: [],
      tableHeight: 0,
      tableData: [],
      titleName: [
        {
          title: "车商名称",
          props: "carServiceProviderName",
        },
        {
          title: "车型分类",
          props: "serviceVehicleModelName",
        },
        {
          title: "座位数",
          props: "seatNum",
        },
        {
          title: "载客数",
          props: "passengerNum",
        },
        {
          title: "行李数",
          props: "luggageNum",
        },
        {
          title: "定制包车",
          props: "customCharterStatus",
          render: (h, params, index) => {
            return h(
              "span",
              {
                style: {
                  color: params.customCharterStatus == 0 ? "green" : "red",
                },
              },
              params.customCharterStatus === 0 ? "已启用" : "未启用"
            );
          },
        },
      ],
      total: 0,
      ruleForm: {},
      show: false,
      disabled: false,
      headerText: "",
      projects: [],
      index: -1,
    };
  },
  mounted () {
    // 获取车商名称下拉框数据
    this.providerListFun().then((res) => {
      this.nameList = res;
    });
    this.getVehicleType();
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    renderData () {
      const obj = {};
      Object.keys(this.form).forEach((i) => {
        if (this.form[i]) {
          obj[i] = this.form[i];
        }
      });
      valuationManagePageAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    //获取车型分类
    getVehicleType () {
      carTypeListAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.typeList = res.data;
        }
      });
    },
    queryFun () {
      this.form.currentPage = 1;
      this.renderData();
    },
    // 分页页数发生变化
    onCurrentChange (val) {
      this.form.currentPage = val;
      this.renderData();
    },
    // 分页change方法
    onSizeChange (val) {
      this.form.pageSize = val;
      this.form.currentPage = 1;
      this.renderData();
    },
    // 点击查看方法
    seeFun (scope) {
      this.disabled = true;
      this.renderPopData(scope, "查看");
    },
    // 点击表格计价按钮
    packageUpdateFun (scope) {
      this.disabled = false;
      this.renderPopData(scope, "配置");
    },
    renderPopData (scope, headerText) {
      this.ruleForm = this.deepClone(scope);
      charteredValuationManagementAPI({ id: scope.id }).then((res) => {
        if (res.code == "SUCCESS") {
          this.ruleForm = Object.assign(this.ruleForm, res.data);
          this.ruleForm.costInfo =
            this.ruleForm.charteredValuationCustom.costInfo;
          this.show = true;
          this.headerText = headerText;
        }
      });
    },
    changeCustomCharterStatus () {
      this.ruleForm.charteredValuationCustom.charteredCostItems.forEach(
        (item) => {
          item.status = 1;
        }
      );
    },
    changeStatus (row) {
      let afterStatus = row.status;
      row.status = row.status == 0 ? 1 : 0;
      if (this.ruleForm.customCharterStatus) {
        this.$message.warning("请先启用定制包车!");
      } else if (row.cost && row.status == 0) {
        this.$confirm("确认关闭该计费项目吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            row.status = afterStatus;
          })
          .catch(() => { });
      } else if (!row.cost) {
        if (row.status == 0) {
          row.status = afterStatus;
        } else {
          this.$message.warning("请填写收费标准!");
        }
      } else {
        row.status = afterStatus;
      }
    },
    confirmBtn () {
      if (this.headerText === "查看") {
        this.closePopupFun();
        return;
      }
      this.ruleForm.charteredValuationCustom.costInfo = this.ruleForm.costInfo;
      let params = {
        id: this.ruleForm.id,
        customCharterStatus: this.ruleForm.customCharterStatus,
        charteredValuationCustom: this.ruleForm.charteredValuationCustom,
      };
      updateCharteredValuationManagementAPI(params).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("操作成功");
          this.closePopupFun();
          this.renderData();
        }
      });
    },
    closePopupFun () {
      this.show = false;
    },
    computeHeight () {
      const wholeHeight = this.$refs.valuationManage.clientHeight;
      const pagination = this.$refs.pagination.clientHeight;
      this.tableHeight = wholeHeight - pagination - 140 + "px";
    },
  },
};
</script>
<style scoped lang="scss">
.tips {
  color: #666666;
  font-size: 14px;
  background-color: #e4ecff;
  border-radius: 6px;
  padding: 12px 16px;
  box-sizing: border-box;
  margin-bottom: 10px;
}
.el-icon-warning {
  color: #336ffe;
  font-size: 16px;
  margin-right: 6px;
}
.valuationManage {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  .flex-box {
    display: flex;
    .text {
      line-height: 28px;
      flex: 1;
      margin-top: -6px;
    }
  }
  .customizedCharterCar-box {
    .head {
      display: flex;
      align-items: center;
      h3 {
        margin-right: 12px;
      }
    }
    .costInfo-box {
      padding: 0 10px;
      display: flex;
      margin-top: 20px;
      .label-title {
        width: 80px;
        color: #828485;        
      }
      .main {
        flex: 1;
        padding: 0 10px;
        box-sizing: border-box;
        .table-box{
          width: 99%;
        }
      }
      ::v-deep .el-textarea__inner {
        padding-bottom: 18px;
      }
    }
  }
  .Table {
    margin-top: 16px;
  }
  .inner {
    padding: 0 20px 20px;
    .el-row {
      padding: 0 10px;
      margin-bottom: 20px;
      .el-col {
        & > span:nth-child(1) {
          display: inline-block;
          width: 80px;
          color: #828485;
        }
      }
    }
    ::v-deep .el-input__inner {
      text-align: center;
    }
  }
}
</style>
